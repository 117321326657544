<template>
    <v-container :fill-height="loading" class="my-3">

        <v-container v-if="loading">
            <LoadingAnimation/>
        </v-container>

        <!--        <div v-if="authUser.authenticated">-->

        <v-row justify="center" v-if="!loading && user.Username">
            <v-col cols="12" md="12" lg="6">
                <v-card class="elevation-5">
                    <v-toolbar color="rfaccent2" dark dense>
                        <v-avatar size="24">
                            <v-icon>mdi-account</v-icon>
                        </v-avatar>

                        <h3 class="title font-weight-light ml-2">
                            <span v-if="$vuetify.breakpoint.smAndUp">User Profile:</span>
                            <small>
                                {{ user.Username && $_.isString(user.Username) ? user.Username.toUpperCase() : '' }}
                            </small>
                        </h3>

                        <v-spacer></v-spacer>

                        <v-chip v-if="user.Banned === 1" color="error" class="mx-1">
                            <v-avatar left>
                                <v-icon>mdi-account-alert</v-icon>
                            </v-avatar>
                            Banned
                        </v-chip>

                        <v-chip v-if="user.Role === 0" color="rfaccent" class="mx-1">
                            <v-avatar left>
                                <v-icon>mdi-crown</v-icon>
                            </v-avatar>
                            Admin
                        </v-chip>

                        <v-chip v-else-if="(user.SubscriptionEndDate * 1000) > Date.now()" color="success" class="mx-1">
                            <v-avatar left>
                                <v-icon>mdi-star</v-icon>
                            </v-avatar>
                            Premium
                        </v-chip>
                    </v-toolbar>
                    <v-container class="px-0 pt-5">
                        <v-row>
                            <v-col cols="12" sm="5" class="text-center pr-0">
                                <UserAvatar
                                    :user="user"
                                    tile
                                />
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list class="py-0">
                                    <v-list-item two-line>
                                        <v-list-item-avatar class="my-0">
                                            <v-icon>mdi-account</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content class="pa-0">
                                            <v-list-item-title>{{ user.Username }}</v-list-item-title>
                                            <v-list-item-subtitle>Username</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line :to="'/license/' + user.Callsign" v-if="user.Callsign">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-card-account-details-star</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ user.Callsign }}</v-list-item-title>
                                            <v-list-item-subtitle>Callsign</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-icon>
                                            <v-icon small>mdi-open-in-new</v-icon>
                                        </v-list-item-icon>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-avatar>
                                            <v-icon>mdi-clock-outline</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ user['Last Login'] | fromNow }}</v-list-item-title>
                                            <v-list-item-subtitle>Last Login</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-text
                        v-if="user.Profile"
                        v-html="user.Profile"
                        :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                    ></v-card-text>
                    <v-card-text v-else-if="!authUser.authenticated">
                        <v-alert type="warning">You must be logged in to see a user's "About Me" section.</v-alert>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="12" lg="6">
                <v-card class="elevation-5">
                    <v-toolbar color="rfaccent2" dark dense>
                        <v-avatar size="24">
                            <v-icon>mdi-radio-tower</v-icon>
                        </v-avatar>

                        <h3 class="title font-weight-light ml-2">Repeaters</h3>

                        <v-spacer></v-spacer>

                        <v-btn color="almostblack" to="/profile/repeaters" v-if="authUser.id === user.ID">
                            My Repeaters
                        </v-btn>
                    </v-toolbar>

                    <v-data-table
                        :headers="repeaterHeaders"
                        :items="repeaters"
                        :footer-props="{'items-per-page-options':[-1]}"
                        mobile-breakpoint="0"
                        hide-default-footer
                        @click:row="rowClick"
                    >
                        <template v-slot:no-data>
                            <v-icon class="mr-1" color="grey">mdi-alert</v-icon>
                            No repeaters available
                        </template>

                        <template v-slot:item.Name="{ item }">
                            <RepeaterStatusIcon :status="item.Status"/>

                            <span class="font-weight-medium"><router-link :to="'/repeater/' + item.ID">{{
                                    item.Name ? item.Name : item.Location
                                }}</router-link></span>

                            <v-icon class="ml-2" color="warning" title="Outdated Listing" small
                                    v-if="moment().diff(moment(item.Modified), 'days') > 365">mdi-alert
                            </v-icon>
                        </template>

                        <template v-slot:item.Location="{ item }">
                            <span>{{ $_.trim(item.Location) }}, {{ item.State }}</span>
                            <!--<v-icon small class="ml-1">mdi-open-in-new</v-icon>-->
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else-if="error.state">
            <v-col cols="12">
                <v-alert type="error" class="mt-5" border="left" elevation="5" prominent>
                    {{ error.message }}
                </v-alert>
            </v-col>
        </v-row>

        <v-row v-else-if="!loading">
            <v-col cols="12">
                <v-alert type="warning" class="mt-5" border="left" elevation="5" prominent>
                    There is no user account for <strong>{{ username }}</strong>.
                </v-alert>
            </v-col>
        </v-row>

        <!--        </div>-->
        <!--        <div v-else>-->
        <!--            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>-->
        <!--                You must be logged in to view user information.-->
        <!--            </v-alert>-->

        <!--            <Login v-on="$listeners"/>-->
        <!--        </div>-->
    </v-container>
</template>

<script>
    import config from '../config';
    import axios from 'axios';
    import moment from 'moment';
    import md5 from 'md5';
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import UserAvatar from '@/components/UserAvatar.vue';
    import RepeaterStatusIcon from '@/components/RepeaterStatusIcon.vue';

    export default {
        name: 'User',

        props: ['username', 'authUser'],

        components: {UserAvatar, LoadingAnimation, RepeaterStatusIcon},

        data: () => ({
            user: {
                Username: null,
                Callsign: null,
                Email: null
            },
            repeaterHeaders: [
                // {text: '', value: 'Status', sortable: false},
                {text: 'Name', value: 'Name', sortable: false},
                {text: 'Location', value: 'Location', sortable: false, align: 'right'},
                // {text: '', value: 'Icon'}
            ],
            repeaters: [],
            loading: false,
            error: {
                state: false,
                message: null
            }
        }),

        filters: {
            fromNow(date) {
                return moment(date).fromNow();
            }
        },

        methods: {
            fetchUser() {
                this.loading = true;

                let axiosHeaders = {};

                if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                axios
                    .get(config.API_LOCATION + '/user/' + this.username, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.user = response.data;
                        if (this.user.Callsign) this.user.Callsign = this.user.Callsign.toUpperCase();

                        this.error.state = false;
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            this.error.message = 'An error occurred while fetching the user details. Please try again later.';
                            this.error.state = true;
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            fetchRepeaters() {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/repeaters?owner=' + this.username + '&outdated=true&offline=true', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.repeaters = response.data.items;
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            rowClick(item) {
                this.$router.push({name: 'repeater', params: {id: item.ID}})
            },

            md5(str) {
                if (!str) return '';
                return md5(str);
            },

            moment(date) {
                return moment(date);
            }
        },

        mounted() {
            this.fetchUser();
            this.fetchRepeaters();
        }
    };
</script>
